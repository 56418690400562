.oil-pay-con {
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;

  .all-textcon {
    width: 100%;
    padding: 10px 20px;
    background-color: #fff;
  }
  .add-text {
    color: #556aa2;
    font-size: 16px;
    padding-left: 10px;
  }

  .bot-key {
    width: 100%;
    height: 370px;
    position: fixed;
    left: 0;
    bottom: 0;
    .bot-key-con {
      width: 100%;
      height: 100%;
      position: relative;
      .open-card {
        width: 100%;
        position: absolute;
        top: 48px;
        left: 0;
      }
      .card-active {
        top: 0;
        animation: open_card 250ms ease;
      }
      .price-show {
        width: 100%;
        height: 51px;
        display: flex;
        align-items: center;
        padding-right: 20px;
        color: #000;
        justify-content: flex-end;
        background-color: #ffffff;
        position: absolute;
        left: 0;
        top: 20%;
        font-weight: 500;
        animation: animate 250ms ease;
        span > span {
          font-size: 26px;
          font-weight: bold;
        }
      }
    }
  }
  .input-money {
    width: 100%;
    height: 65px;
    display: flex;
    border-bottom: 1px solid #f4f4f4;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 0 20px;
    .left-text {
      font-size: 16px;
      color: #000;
    }
    .set-color {
      color: #556aa2;
    }
    .right-text {
      display: flex;
      align-items: flex-end;
      .price {
        height: 30px;
        font-size: 22px;
        // font-weight: bold;
        align-items: flex-end;
      }
      .line {
        width: 2px;
        height: 30px;
        margin: 0 7px 0 10px;
        background-color: #9dc7b8;
        animation: scaleout 1s infinite ease-in-out;
      }
      .yuan {
        font-size: 12px;
        color: #c6c4c5;
        margin-bottom: 3px;
      }
    }
  }
  .store-info {
    width: 100%;
    height: 114px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dfdfdf;
    background-color: #fff;
    .left-con {
      display: flex;
      flex-direction: column;
      .big-name {
        font-size: 18px;
        color: #000;
      }
      .min-name {
        font-size: 13px;
        color: #b5b5b5;
      }
    }
    .right-con {
      width: 52px;
      height: 52px;
      //background-color: #3bb272;
      border-radius: 21px;
      padding: 10px;
    }
  }
}

@keyframes scaleout {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
    opacity: 0;
  }
}

@keyframes animate{
  from {
    top : 40%;
  }
  to {
    top : 20%;
  }
}

@keyframes open_card{
  from {
    top : 48px;
  }
  to {
    top : 0;
  }
}

.adm-number-keyboard-key {
  height: 60px;
  border: solid 4px #f5f5f5;
}

.adm-number-keyboard-key:active {
  background: #333333;
}

.adm-number-keyboard-confirm {
  width: 23%;
  .bs-key {
    border: solid 4px #f5f5f5;
  }
  .ok-key {
    height: 185px;
    border: solid 4px #f5f5f5;
    background: #435fd5;
  }
}


.adm-number-keyboard-popup.adm-popup {
  z-index: 300;
}